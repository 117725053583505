import CloudProvider, { CloudProviderClassProps } from './CloudProvider';
import DatabaseVendor, { DatabaseVendorClassProps } from './DatabaseVendor';
import InstanceVolumeIops from './InstanceVolumeIops';
import InstanceVolumeType from './InstanceVolumeType';
import InstanceVolumeSize from './InstanceVolumeSize';
import InstanceVolumeTypes from './InstanceVolumeTypes';
import InstanceVolumeSizes from './InstanceVolumeSizes';
import InstanceVolumeIopsValue from './InstanceVolumeIopsValue';
import CloudProviderRegion from './CloudProviderRegion';
import InstanceSize from './InstanceSize';
import InstanceSizes from './InstanceSizes';
import CloudProviderGroup, {
    CloudProviderGroupClassProps,
} from './CloudProviderGroup';

export type DeploymentOptionsClassProps = {
    cloud: any;
    database: any;
    instance: any;
    network: any;
};

export interface DeploymentOptionsInterface {
    cloud: any;
    database: any;
    instance: any;
    network: any;
    getCloudProviders: Function;
    getCloudRegions: Function;
    getDatabaseVendors: Function;
    getDatabaseVendorByCode: Function;
    getDatabaseVendorsWithVersion: Function;
    getDatabaseInfo: Function;
    getNumberOfNodesInfo: Function;
    getInstanceSizes: Function;
    getInstanceSizesInfo: Function;
    getVolumeIops: Function;
    getVolumeIopsInfo: Function;
    getVolumeSize: Function;
    getVolumeSizesInfo: Function;
    getVolumeTypes: Function;
    getVolumeTypesInfo: Function;
    getAvailabilityZones: Function;
    getNetworks: Function;
    getNetworkInfo: Function;
    getNetworkTypeInfo: Function;
    getNetworkVpcInfo: Function;
}

export default class DeploymentOptions implements DeploymentOptionsInterface {
    readonly cloud: any;
    readonly database: any;
    readonly instance: any;
    readonly network: any;

    constructor(props: DeploymentOptionsClassProps) {
        this.cloud = {};
        this.cloud.cloudProviders = props.cloud.cloud_providers.map(
            (p: CloudProviderClassProps) => {
                return new CloudProvider(p);
            }
        );
        this.cloud.cloudGroups = props.cloud.cloud_groups.map(
            (p: CloudProviderGroupClassProps) => {
                return new CloudProviderGroup(p);
            }
        );
        this.database = props.database;
        this.database.vendors = props.database.vendors.map(
            (v: DatabaseVendorClassProps) => {
                return new DatabaseVendor(v);
            }
        );
        this.instance = props.instance;
        this.instance.volumeTypes = new InstanceVolumeTypes(
            props.instance.volume_types
        );
        this.instance.volumeSizes = new InstanceVolumeSizes(
            props.instance.volume_sizes
        );
        this.instance.volumeIops = new InstanceVolumeIops(
            props.instance.volume_iops
        );

        this.instance.instanceSizes = new InstanceSizes(
            props.instance.instance_sizes
        );

        this.network = props.network;
    }

    getCloudProviders(): any[] {
        const tempCloudProvidersWithoutGroups: any[] =
            this.cloud?.cloudProviders
                ?.filter((p: CloudProviderClassProps) => !p.group)
                .map((provider: any) => new CloudProvider({ ...provider })) ||
            [];

        this.cloud?.cloudProviders.forEach(
            (cloudProvider: CloudProviderClassProps) => {
                if (cloudProvider.group !== '') {
                    const isInList = tempCloudProvidersWithoutGroups.find(
                        (provider: any) =>
                            provider.group === cloudProvider.group
                    );

                    if (!isInList) {
                        const cloudGroupName = this.getCloudProviderGroupByName(
                            cloudProvider.group
                        );
                        const temp = { ...cloudProvider }; // Create a shallow copy
                        temp.code = cloudGroupName.toLowerCase();
                        temp.name = cloudGroupName;
                        tempCloudProvidersWithoutGroups.push(
                            new CloudProvider({ ...temp })
                        );
                    } else {
                        isInList.regions = [
                            ...new Set([
                                ...isInList.regions,
                                ...cloudProvider.regions,
                            ]),
                        ];
                    }
                }
            }
        );

        return tempCloudProvidersWithoutGroups;
    }

    getCloudProvidersGroups(): CloudProviderGroup[] {
        return this.cloud?.cloudGroups;
    }

    getDefaultCloudProviders(): CloudProvider {
        return this.cloud?.cloudProviders[0];
    }

    getCloudRegions(cloudProviderCode: string) {
        return this.cloud?.cloudProviders?.filter(
            (p: CloudProvider) => p.code === cloudProviderCode
        )[0]?.regions;
    }

    getCloudRegion(cloudProviderCode: string, cloudRegion: string) {
        return this.getCloudRegions(cloudProviderCode)?.filter(
            (region: any) => {
                return region.code === cloudRegion;
            }
        )[0]?.countryCode;
    }

    getCloudProviderLogo(cloudProviderCode: string | undefined) {
        return this.cloud?.cloudProviders?.filter(
            (p: CloudProvider) => p.code === cloudProviderCode
        )[0]?.logo;
    }

    getCloudProviderGroup(cloudProviderCode: string | undefined) {
        return this.cloud?.cloudProviders?.filter(
            (p: CloudProvider) => p.code === cloudProviderCode
        )[0]?.group;
    }

    getCloudProvider(cloudProviderCode: string | undefined) {
        return this.cloud?.cloudProviders?.find(
            (p: CloudProvider) => p.code === cloudProviderCode
        );
    }

    getFullName(cloudProviderCode: string) {
        return this.cloud?.cloudProviders
            ?.filter((p: CloudProvider) => {
                if (
                    p.code === cloudProviderCode ||
                    p.group === cloudProviderCode
                ) {
                    return p.getFullName();
                }
            })[0]
            ?.getFullName();
    }

    isOpenStack(cloudProviderCode: string) {
        return this.cloud?.cloudProviders?.filter(
            (p: CloudProvider) => p.code === cloudProviderCode
        )[0]?.is_open_stack;
    }

    getDatabaseVendors(): DatabaseVendor[] {
        return this.database?.vendors;
    }

    getDatabaseVendorByCode(code: string): DatabaseVendor {
        return this.database?.vendors.find(
            (v: DatabaseVendor) => v.code === code
        );
    }

    getDatabaseVendorsWithVersion(): any[] {
        return this.getDatabaseVendors().map((vendor: DatabaseVendor) => {
            return `${vendor.name} ${vendor.version}`;
        });
    }

    getDatabaseInfo(): string {
        return this.database?.deployment_types_info;
    }

    getNumberOfNodesInfo(): string {
        return this.database?.num_nodes_info;
    }

    getInstanceSizes(cloudProvider: CloudProvider): InstanceSize[] {
        if (!cloudProvider) throw new Error('Missing parameter: cloudProvider');
        return this.instance?.instanceSizes[cloudProvider.code];
    }

    getInstanceSizesInfo(): string {
        return this.instance?.instance_sizes?.instance_sizes_info;
    }

    getVolumeIops(
        cloudProvider: CloudProvider,
        volumeType: InstanceVolumeType
    ): InstanceVolumeIopsValue {
        if (!cloudProvider) throw new Error('Missing parameter: cloudProvider');
        if (!volumeType) throw new Error('Missing parameter: volumeType');
        return this.instance?.volume_iops[cloudProvider.code][volumeType.code];
    }

    getVolumeIopsInfo(): string {
        return this.instance?.volume_iops?.volume_iops_info;
    }

    getVolumeSize(
        cloudProvider: CloudProvider,
        volumeType: InstanceVolumeType
    ): InstanceVolumeSize {
        if (!cloudProvider) throw new Error('Missing parameter: cloudProvider');
        if (!volumeType) throw new Error('Missing parameter: volumeType');
        return this.instance?.volume_sizes[cloudProvider.code][volumeType.code];
    }

    getVolumeSizesInfo(): string {
        return this.instance?.volume_sizes_info;
    }

    getVolumeTypes(cloudProvider?: CloudProvider): any[] {
        if (!cloudProvider) throw new Error('Missing parameter: cloudProvider');
        return this.instance?.volumeTypes[cloudProvider.code];
    }

    getVolumeTypesInfo(): string {
        return this.instance?.volume_types?.volume_types_info;
    }

    getAvailabilityZones(
        cloudProvider: CloudProvider,
        cloudRegion: CloudProviderRegion
    ): any[] {
        if (!cloudProvider) throw new Error('Missing parameter: cloudProvider');
        if (!cloudRegion) throw new Error('Missing parameter: cloudRegion');
        return this.network?.availability_zones[cloudProvider.code][
            cloudRegion.code
        ];
    }

    getNetworks(cloudProvider?: CloudProvider): any[] {
        if (!cloudProvider) throw new Error('Missing parameter: cloudProvider');
        return this.network?.network[cloudProvider.code];
    }

    getNetworkInfo(cloudProvider: CloudProvider): string {
        if (!cloudProvider) throw new Error('Missing parameter: cloudProvider');
        return this.network?.network_info[cloudProvider.code];
    }

    getNetworkTypeInfo(cloudProvider: CloudProvider): string {
        if (!cloudProvider) throw new Error('Missing parameter: cloudProvider');
        return this.network?.type_info[cloudProvider.code];
    }

    getNetworkVpcInfo(cloudProvider: CloudProvider): string {
        if (!cloudProvider) throw new Error('Missing parameter: cloudProvider');
        return this.network?.vpc_info[cloudProvider.code];
    }

    getCloudProviderGroupByName(name: string): string {
        const isGrouped = this.cloud?.cloudGroups.find(
            (p: CloudProviderGroup) => {
                return name.includes(p.name);
            }
        );
        if (isGrouped) {
            return isGrouped.label;
        } else {
            return name;
        }
    }
}
